<!--
  功能：结算金额组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年03月30日 15:18:47
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <span v-if="dealMoney || dealMoney === 0" :style="{ color: dealMoney < 0 ? 'red' : '' }">
    {{ dealMoney| orderMoneyFormat }}
  </span>
  <span v-else>--</span>
</template>

<script>
  export default {
    name: 'SettlementCurrency',
    props: {
      money: {
        type: [Number, String],
        default: '0',
      },
      invisibleJudgment: {
        type: [Boolean],
        default: false,
      },
      control: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      dealMoney() {
        if (this.money === null || this.money === undefined) {
          return this.money
        }
        return Number(this.money)
      },
    },
  }
</script>

<style scoped lang="scss"></style>
