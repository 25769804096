<!--
  功能：结算列表金额组件
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年03月30日 15:18:47
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <span v-if="money || money === 0">
    <span v-if="money !== '***'" :style="{ color: money * 1 < 0 ? 'red' : '' }">
      {{ money | orderMoneyFormat }}
    </span>
    <span v-else>{{ money }}</span>
  </span>
  <span v-else>--</span>
</template>

<script>
  export default {
    name: 'SettlementCurrency',
    props: {
      money: {
        type: String | Number,
        default: '0',
      }
    },
  }
</script>

<style scoped lang="scss"></style>