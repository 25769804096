<!--
  功能：订单结算表格
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2022年04月02日 16:42:02
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="content-wrapper">
    <el-form :model="form" :rules="rules" ref="form">
      <el-table :data="form.tableData" :span-method="objectSpanMethod" use-virtual :max-height="tableHeight"
        class="dyn-table" border stripe resize v-loading="tableLoading" element-loading-text="数据处理中">
        <el-table-column align="center" label="#" width="80px">
          <template slot-scope="scope">
            {{ getIndex(scope.row.salesOrder) }}
          </template>
        </el-table-column>

        <el-table-column label="结算备注" width="200px" align="center" v-if="isAudit">
          <template slot-scope="scope">
            <span>
              {{
              scope.row.settlementRemark ? scope.row.settlementRemark : '--'
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column v-for="(item, index) in columns" :key="index" :label="item.label" show-overflow-tooltip
          :width="item.width" min-width="120" :resizable="false" align="center" :prop="item.prop" show-overflow-tooltip>
          <template #default="{ row, $index }">
            <div v-if="item.prop === 'settlementOrder'">
              <span class="page-link" @click="showSettlementDetails(row)">
                {{ row.settlementOrder }}
              </span>
            </div>
            <!-- 公司名称 -->
            <div v-else-if="item.prop === 'company'" class="whi">
              {{ row.company ? row.company : '--' }}
            </div>
            <!-- 产品信息 -->
            <div v-else-if="item.prop === 'sku'">
              <div class="product-info">
                <p class="left">
                  <span class="pro-item">
                    {{ row.sku }}
                  </span>
                  <span class="pro-item">
                    {{ row.productNameCn }}
                  </span>
                </p>
                <p>
                  <!-- 产品类型 -->
                  <el-tag v-if="row.productStandardCustomized">
                    {{ row.productStandardCustomized }}
                  </el-tag>
                  <el-tag type="warning" v-if="row.productionLine">
                    {{ row.productionLine }}
                  </el-tag>
                </p>
              </div>
            </div>

            <div v-else-if="item.prop === 'settlementAttach'" @click="setSettlementUsers(row)">
              <span v-if="!row['settlementAttach']" class="page-link">
                未设置
              </span>
              <span v-else class="page-link">
                {{ row['settlementAttach'] }}
              </span>
            </div>
            <!-- 订单编号 -->
            <div v-else-if="item.prop === 'salesOrder'">
              <span class="page-link" @click="showOrderDetail(row)">
                {{ row.salesOrder }}
              </span>
            </div>
            <!-- 结算状态 -->
            <div v-else-if="item.prop === 'settlementStatus'">
              <el-tag :type="row.tagType">
                {{ row.settlementStatus | settlementStatus }}
              </el-tag>
            </div>

            <div v-else-if="item.isMoney">
              <SettlementCurrency :money="row[item.prop]" v-if="!isDetail" />
              <SettlementListCurrency :money="row[item.prop]" v-else />
            </div>

            <!-- 新增编辑时是输入框 -->
            <div v-else-if="item.isInput">
              <el-form-item v-if="contentType !== 'detail'" :prop="'tableData.' + $index + '.' + item.prop" :rules="[
                  {
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                  },
                ]">
                <el-input-number :min="0" :max="100" :precision="2" :controls="false" clearable v-model="row[item.prop]"
                  placeholder="请输入" @focus="onFocus(row, item.prop)"
                  @change="onInputChange(row, item.prop)"></el-input-number>
              </el-form-item>
              <span v-else>
                <span v-if="!row[item.prop] && row[item.prop] !== 0">--</span>
                <span v-else :style="{ color: row[item.prop] < 0 ? 'red' : '' }">
                  {{ row[item.prop] }}
                </span>
              </span>
            </div>
            <div v-else-if="item.isUser">
              <el-form-item v-if="contentType !== 'detail'">
                <el-input placeholder="请选择" v-model="row[item.prop]" clearable
                  @focus="onSelectUser(row, item.prop, index)"></el-input>
              </el-form-item>
              <span v-else>
                <span v-if="!row[item.prop] && row[item.prop] !== 0">--</span>
                <span v-else :style="{ color: row[item.prop] < 0 ? 'red' : '' }" style="
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    max-width: 140px;
                  ">
                  {{ row[item.prop] }}
                </span>
              </span>
            </div>

            <div v-else style="overflow: hidden; text-overflow: ellipsis">
              <span v-if="!row[item.prop] && row[item.prop] !== 0">--</span>
              <span v-else :style="{ color: row[item.prop] < 0 ? 'red' : '' }">
                {{ row[item.prop] }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="结算备注" width="200px" :fixed="fixed" align="center" v-if="!isAudit" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="padding5">
              <el-input v-if="contentType !== 'detail'" type="textarea" maxlength="500"
                v-model="scope.row.settlementRemark" @change="
                  (val) => onSettlementRemarkChange(val, scope.row.salesOrder)
                "></el-input>
              <span v-else style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: inline-block;

                  width: 100%;
                ">
                {{
                scope.row.settlementRemark ? scope.row.settlementRemark : '--'
                }}
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="!isAdjust" align="center" :label="isAudit ? '已审核备注' : '审核备注'" fixed="right" width="200px"
          prop="settlementAuditRemark" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="!scope.row.settlementAuditRemark">--</span>
            <p v-else :key="index" v-for="(item, index) in scope.row.settlementAuditRemark
                .split('\n')
                .filter((s) => s)" style="width: 100%">
              <span v-if="item.split(':')[1]" style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: inline-block;

                  width: 100%;
                ">
                {{ item }}
              </span>
              <span v-else style="
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: inline-block;
                  width: 100%;
                ">
                {{ item.split(':')[0] }}:-
              </span>
            </p>
          </template>
        </el-table-column>
        <el-table-column label="审核备注" width="200px" :fixed="fixed" align="center" v-if="isAudit && !isAdjust">
          <template slot-scope="scope">
            <div class="padding5">
              <el-input type="textarea" v-model="scope.row.auditRemark" maxlength="500"></el-input>
            </div>
          </template>
        </el-table-column>

        <!--结算调整 结算调整备注-->
        <el-table-column label="结算调整备注" width="200px" align="center" v-if="isAdjust" show-overflow-tooltip
          prop="adjustmentReason">
          <template slot-scope="scope">
            <span style="
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                width: 100%;
              ">
              {{
              scope.row.adjustmentReason ? scope.row.adjustmentReason : '-'
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="80px" :fixed="fixed" align="center"
          v-if="contentType === 'add' || contentType === 'edit' || isAudit">
          <template slot-scope="scope">
            <el-tooltip class="item" :content="isAudit ? '移除' : '删除'" placement="top">
              <el-button type="danger" plain size="mini" @click="delPro(scope.$index)" icon="el-icon-delete-solid"
                circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>

<script>
  import { columnsFilter, fieldsDependNetProfit } from '../helper'
  import SettlementCurrency from './settlement-currency'
  import SettlementListCurrency from './settlement-list-currency'
  import { genNumberLength } from 'kits'
  import { OrderSettlementInteractor } from '@/core'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  const columns = columnsFilter((item) => item.showAtListPage !== true)
  const mergeColumns = [
    '订单编号',
    '订单月份',
    '客户代表',
    '地区',
    '公司名称',
    '结算备注',
    '审核备注',
    '操作',
    '已审核备注',
  ]
  const isAuditColumns = [
    '订单编号',
    '订单月份',
    '客户代表',
    '地区',
    '公司名称',
    '审核备注',
    '结算调整备注',
    '操作',
    '已审核备注',
  ]
  export default {
    name: 'OrderSettlementContent',
    props: {
      contentType: {
        type: String,
        default: 'add', //三种类型 add新增 edit 编辑 detail 详情
      },
      isAudit: {
        type: Boolean,
        default: false,
      },
      list: {
        type: Array,
        default: () => [],
      },
      fixed: {
        type: String,
        default: 'right',
      },
      tableHeight: {
        type: Number,
        default: 400,
      },

      isAdjust: {
        // 是否是结算调整
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        columns,
        form: {
          tableData: [],
        },
        rules: {},

        spanArr: [],
        pos: 0,
        indexsCache: {},
        tableLoading: false,
      }
    },

    computed: {
      isDetail() {
        return !!this.$route.query.isDetail
      },
    },
    components: {
      SettlementCurrency,
      SettlementListCurrency,
    },

    methods: {
      showLoading() {
        this.tableLoading = true
      },
      hideLoading() {
        this.tableLoading = false
      },
      showOrderDetail({ salesOrder }) {
        navigateTo(this, {
          pageType: PAGE_TYPES.salesOrderDetail,
          orderNumber: salesOrder,
          roleJudge: false,
        })
      },

      getIndex(salesOrder) {
        return this.indexsCache[salesOrder]
      },

      salesOrder2Index(tableData) {
        this.indexsCache = {}
        let length = Array.from(
          new Set(tableData.map((i) => i.salesOrder))
        ).length,
          indexArray = genNumberLength(length)
        tableData.forEach((item, index) => {
          if (!this.indexsCache[item.salesOrder]) {
            this.indexsCache[item.salesOrder] = indexArray[0]
            indexArray.shift()
          }
        })
      },

      onSettlementRemarkChange(val, salesOrder) {
        this.form.tableData.forEach((item) => {
          if (item.salesOrder === salesOrder) {
            item.settlementRemark = val
          }
        })
      },
      /**
       * 合并单元格getSpanArr
       */
      getSpanArr(list) {
        for (var i = 0; i < list.length; i++) {
          if (i === 0) {
            this.spanArr.push(1)
            this.pos = 0
          } else {
            if (list[i].salesOrder === list[i - 1].salesOrder) {
              this.spanArr[this.pos] += 1
              this.spanArr.push(0)
            } else {
              this.spanArr.push(1)
              this.pos = i
            }
          }
        }
      },
      /**
       * 合并单元格objectSpanMethod
       */
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (
          columnIndex <= 5 ||
          (!this.isAudit && mergeColumns.includes(column.label)) ||
          (this.isAudit && isAuditColumns.includes(column.label))
        ) {
          const _row = this.spanArr[rowIndex]
          const _col = _row > 0 ? 1 : 0
          return {
            rowspan: _row,
            colspan: _col,
          }
        }
      },
      /**
       * 人员选择
       */
      onSelectUser(row, userProp, index) {
        event.target.blur()
        const idKey = userProp + 'Id'
        const id = row[idKey]
        const name = row[userProp]
        this.$userChoose(this)
          .showPromisedUserAddEdit({
            str: '',
            roleId: id
              ? [
                {
                  id,
                  name,
                },
              ]
              : '',
            singleSelect: true,
            userStatus: 1,
          })
          .then((users) => {
            if (Array.isArray(users) && users.length > 0) {
              const { id, name, englishName } = users[0]
              row[userProp] = `${name} ${englishName}`
              row[idKey] = id
            } else {
            }
            this.$refs.form.validate()
          })
      },
      getTotalCommission(row) {
        const {
          businessCommission,
          productOperatingCommission,
          buyerCommission,
          orderSupportCommission,
          businessManagerCommission,
          productOperatingManagerCommission,
          buyerManagerCommission,
          orderSupportManagerCommission,
        } = row

        return (
          (businessCommission * 1000 +
            productOperatingCommission * 1000 +
            buyerCommission * 1000 +
            orderSupportCommission * 1000 +
            businessManagerCommission * 1000 +
            productOperatingManagerCommission * 1000 +
            buyerManagerCommission * 1000 +
            orderSupportManagerCommission * 1000) /
          1000
        )
      },
      onFocus(row, changedProp) {
        if (row[changedProp] === 0) {
          row[changedProp] = undefined
        }
      },
      onInputChange(row, changedProp) {
        const targetProp = this._getTargetProp(changedProp)
        if (targetProp === 'operatingCost') {
          // 计算运营成本
          const operatingCost = (row[changedProp] * row.grossProfitAmount) / 100
          row.operatingCost = operatingCost
          // 运营成本改变重新计算(净利润 = 毛利额-运营成本)
          row.netProfit =
            (row.grossProfitAmount * 1000 - operatingCost * 1000) / 1000
          //计算依赖净利润的金额
          fieldsDependNetProfit.forEach((field) => {
            row[field] = (row[field + 'Ratio'] * row.netProfit) / 100
          })
          // 计算利润率
          if (row.amountCollectedCny === 0 || row.amountCollectedCny == '0.00') {
            row.orderProfitMargin = 0
          } else {
            row.orderProfitMargin = (
              (row.netProfit * 100) /
              row.amountCollectedCny
            ).toFixed(2)
          }
        } else {
          if (row[changedProp] == 0 || row[changedProp] == '0.00') {
            row[targetProp] = 0
          } else {
            //运营成本金额根据毛利额计算
            row[targetProp] =
              changedProp === 'operatingCostRatio'
                ? (row['grossProfitAmount'] * row[changedProp]) / 100
                : (row['netProfit'] * row[changedProp]) / 100
          }
        }
        // 计算净利润
        row.totalCommission = this.getTotalCommission(row)
        //计算公司利润
        row.companyProfit = row.netProfit - row.totalCommission
      },
      _getTargetProp(prop) {
        return prop && prop.replace('Ratio', '')
      },

      /**
       * 初始化计算
       */
      _initCalucate(item) {
        // 计算运营成本
        item.operatingCost =
          (item.operatingCostRatio * item.grossProfitAmount) / 100
        // 计算净利润
        item.netProfit =
          (item.grossProfitAmount * 1000 - item.operatingCost * 1000) / 1000
        //计算依赖净利润的金额

        fieldsDependNetProfit.forEach((field) => {
          item[field] = (item[field + 'Ratio'] * item.netProfit) / 100
        })
        // 计算利润率
        if (item.amountCollectedCny === 0 || item.amountCollectedCny === '0.00') {
          item.orderProfitMargin = 0
        } else {
          item.orderProfitMargin = (
            (item.netProfit * 100) /
            item.amountCollectedCny
          ).toFixed(2)
        }
        item.totalCommission = this.getTotalCommission(item)
        //计算公司利润
        item.companyProfit =
          (item.netProfit * 1000 - item.totalCommission * 1000) / 1000
        return item
      },

      /**
       *确认
       */
      confirm() {
        this.showLoading()
        this.$refs.form.validate((flag) => {
          if (flag) {
            this.$emit('confirm', this.form.tableData)
          }

          this.hideLoading()
        })
      },
      /**
       * 保存
       */
      save() {
        this.$emit('save', this.form.tableData)
      },
      // 订单结算审核删除判断
      _delSenseIsAuditAndIsSettlement(index) {
        const tableData = this.form.tableData
        const settlementOrders = new Set(
          tableData.map((i) => i.settlementOrder)
        )

        if (settlementOrders.size == 1) {
          return this.$message.warning('至少保留1条数据')
        }
        const settlementOrder = tableData[index].settlementOrder
        this.form.tableData = tableData.filter(
          (i) => i.settlementOrder !== settlementOrder
        )
      },

      /**
       * 删除产品
       */
      delPro(index) {
        if (this.isAudit && !this.isAdjust) {
          return this._delSenseIsAuditAndIsSettlement(index)
        }
        const tableData = this.form.tableData
        const salesOrders = Array.from(
          new Set(tableData.map((i) => i.salesOrder))
        )

        if (salesOrders.length === 1) {
          return this.$message.warning('至少保留1条数据')
        }
        const settlementSalesOrderId = tableData[index].settlementSalesOrderId

        // 批量审核移除数据
        if (this.isAudit) {
          this.spanArr = []
          this.pos = 0
          this.form.tableData = this.form.tableData.filter(
            (i) => i.salesOrder !== tableData[index].salesOrder
          )

          this.salesOrder2Index(this.form.tableData)
          this.getSpanArr(this.form.tableData)
          return
        }

        this.$confirm('确认删除？删除后将回到未结算状态', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            OrderSettlementInteractor.deleteSalesOrderApi(
              settlementSalesOrderId
            ).then((res) => {
              if (res && res.code === '000000') {
                this.spanArr = []
                this.pos = 0
                this.form.tableData = this.form.tableData.filter(
                  (i) => i.salesOrder !== tableData[index].salesOrder
                )

                this.salesOrder2Index(this.form.tableData)
                this.getSpanArr(this.form.tableData)
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            })
          })
      },
    },

    created() {
      this.salesOrder2Index(this.list)
      if (!this.isDetail && !this.isAudit) {
        this.form.tableData = this.list.map((item) => {
          return this._initCalucate(item)
        })
      } else {
        this.form.tableData = this.list
      }

      this.getSpanArr(this.form.tableData)
    },
  }
</script>

<style scoped lang="scss">
  .el-form-item--small .el-form-item__error {
    padding-top: 2px;
    position: relative;
    left: -50px;
  }

  .padding5 {
    padding: 5px;
  }

  .product-info {
    display: flex;
    padding: 10px;
    text-align: left;

    .pro-item {
      width: 100%;
      white-space: pre-wrap;
      margin-bottom: 5px;
    }

    .left {
      flex: 1;
    }

    p {
      display: flex;
      flex-direction: column;

      .el-tag {
        margin-bottom: 5px;
        width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .whi {
    white-space: pre-wrap;
  }

  .fixed-table {
    .el-table__body-wrapper {
      display: none !important;
    }
  }
</style>