/**
 * showAtListPage：只在列表页展示的字段
 * isInput: 是否是输入框
 * quadratureMember:求积成员
 */
const columns = [
  {
    label: '结算类型',
    width: '150',
    prop: 'settlementTypeDesc',
    isShow: true,
    checked: true,
    showAtListPage: true,
  },
  {
    label: '结算单号',
    width: '150',
    prop: 'settlementOrder',
    isShow: true,
    checked: true,
    showAtListPage: true,
  },
  {
    label: '结算日期',
    width: '150',
    prop: 'settlementDate',
    isShow: true,
    checked: true,
    showAtListPage: true,
  },
  {
    label: '结算专员',
    width: '150',
    prop: 'settlementAttach',
    isShow: true,
    checked: true,
    showAtListPage: true,
  },
  {
    label: '结算状态',
    width: '150',
    prop: 'settlementStatus',
    isShow: true,
    checked: true,
    showAtListPage: true,
  },
  {
    label: '结算备注',
    width: '150',
    prop: 'settlementRemark',
    isShow: true,
    checked: true,
    showAtListPage: true,
  },
  {
    label: '订单编号',
    width: '150',
    prop: 'salesOrder',
    isShow: true,
    checked: false,
  },
  {
    label: '订单月份',
    width: '150',
    prop: 'orderCreateTime',
    isShow: true,
    checked: false,
  },
  {
    label: '客户代表',
    width: '150',
    prop: 'businessName',
    isShow: true,
    checked: false,
  },
  {
    label: '地区',
    width: '150',
    prop: 'businessArea',
    isShow: true,
    checked: true,
  },
  {
    label: '公司名称',
    width: '150',
    prop: 'company',
    isShow: true,
    checked: true,
  },
  {
    label: '产品信息',
    width: '220',
    prop: 'sku',
    isShow: true,
    checked: true,
  },
  {
    label: '订单币种',
    width: '150',
    prop: 'orderCurrency',
    isShow: true,
    checked: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '收入金额',
    width: '150',
    prop: 'amountCollected',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '汇率',
    width: '150',
    prop: 'exchangeRate',
    isShow: true,
    checked: true,
    invisibleJudgment: true, //可见性判断
  },
  // {
  //   label: '销售数量',
  //   width: '150',
  //   prop: 'salesNum',
  //   isShow: true,
  //   checked: true,
  //   invisibleJudgment: true, //可见性判断
  // },
  {
    label: '销售合同金额',
    width: '150',
    prop: 'salesOrderAmount',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '收入金额(￥)',
    width: '150',
    prop: 'amountCollectedCny',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '收款手续费(￥)',
    width: '150',
    prop: 'commissionAmount',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  // {
  //   label: '采购数量',
  //   width: '150',
  //   prop: 'purchaseNum',
  //   isShow: true,
  //   checked: true,
  //   isMoney: true,
  //   invisibleJudgment: true, //可见性判断
  // },

  // {
  //   label: '采购合同金额',
  //   width: '150',
  //   prop: 'purchaseOrderAmount',
  //   isShow: true,
  //   checked: true,
  //   isMoney: true,
  //   invisibleJudgment: true, //可见性判断
  // },
  // {
  //   label: '采购付款金额',
  //   width: '150',
  //   prop: 'purchaseOrderPayAmount',
  //   isShow: true,
  //   checked: true,
  //   isMoney: true,
  //   invisibleJudgment: true, //可见性判断
  // },
  // {
  //   label: '出库数量',
  //   width: '150',
  //   prop: 'warehouseOutNum',
  //   isShow: true,
  //   checked: true,
  //   isMoney: false,
  //   invisibleJudgment: true, //可见性判断
  // },

  // {
  //   label: '出库金额',
  //   width: '150',
  //   prop: 'warehouseOutAmount',
  //   isShow: true,
  //   checked: true,
  //   isMoney: true,
  //   invisibleJudgment: true, //可见性判断
  // },
  // {
  //   label: '入库数量',
  //   width: '150',
  //   prop: 'warehouseInNum',
  //   isShow: true,
  //   checked: true,
  //   isMoney: false,
  //   invisibleJudgment: true, //可见性判断
  // },
  // {
  //   label: '入库成本(不含税)',
  //   width: '150',
  //   prop: 'warehouseInCostExcluding',
  //   isShow: true,
  //   checked: true,
  //   isMoney: true,
  //   invisibleJudgment: true, //可见性判断
  // },
  // {
  //   label: '入库成本(含税)',
  //   width: '150',
  //   prop: 'warehouseInCostIncluding',
  //   isShow: true,
  //   checked: true,
  //   isMoney: true,
  //   invisibleJudgment: true, //可见性判断
  // },
  {
    label: '产品成本(￥)',
    width: '150',
    prop: 'productCost',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '出库成本(￥)',
    width: '150',
    prop: 'warehouseOutAmount',
    isShow: true,
    checked: true,
    isMoney: true,
  },
  {
    label: '采购成本(￥)',
    width: '150',
    prop: 'purchaseOrderAmount',
    isShow: true,
    checked: true,
    isMoney: true,
  },
  {
    label: '用料成本(￥)',
    width: '150',
    prop: 'processUseAmount',
    isShow: true,
    checked: true,
    isMoney: true,
  },
  {
    label: '订单数量',
    width: '150',
    prop: 'salesNum',
    isShow: true,
    checked: true,
    isMoney: false,
  },
  {
    label: '采购数量',
    width: '150',
    prop: 'purchaseNum',
    isShow: true,
    checked: true,
    isMoney: false,
  },

  {
    label: '出库数量',
    width: '150',
    prop: 'warehouseOutNum',
    isShow: true,
    checked: true,
    isMoney: false,
  },
  {
    label: '用料数量',
    width: '150',
    prop: 'processUseNum',
    isShow: true,
    checked: true,
    isMoney: false,
  },
  {
    label: '国际快递费(￥)',
    width: '150',
    prop: 'internationalDeliveryCharge',
    isShow: true,
    checked: true,
    isMoney: true,
  },

  {
    label: '空海运费(￥)',
    width: '150',
    prop: 'airSeaFreight',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '国内运费(￥)',
    width: '150',
    prop: 'homeFreight',
    isShow: true,
    checked: true,
    isMoney: true,
  },
  {
    label: '其他费用(￥)',
    width: '150',
    prop: 'otherCharges',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '总成本(￥)',
    width: '150',
    prop: 'totalCost',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '毛利额(￥)',
    width: '150',
    prop: 'grossProfitAmount',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '毛利率(%)',
    width: '150',
    prop: 'grossProfitMargin',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '运营成本比例（%）',
    width: '150',
    prop: 'operatingCostRatio',
    isShow: true,
    checked: true,
    isInput: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '运营成本金额(￥)',
    width: '150',
    prop: 'operatingCost',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '净利润(￥)',
    width: '150',
    prop: 'netProfit',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '订单利润率（%）',
    width: '150',
    prop: 'orderProfitMargin',
    isShow: true,
    checked: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '季度业绩($)',
    width: '150',
    prop: 'quarterly',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '客户代表提成比例（%）',
    width: '190',
    prop: 'businessCommissionRatio',
    isShow: true,
    checked: true,
    isInput: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '客户代表提成(￥)',
    width: '150',
    prop: 'businessCommission',
    isShow: true,
    checked: true,
    invisibleJudgment: true, //可见性判断
    isMoney: true,
  },
  {
    label: '产品运营',
    width: '150',
    prop: 'productOperating',
    isShow: true,
    checked: true,
  },
  {
    label: '产品运营地区',
    width: '150',
    prop: 'productOperatingArea',
    isShow: true,
    checked: true,
  },
  {
    label: '产品运营提成比例（%）',
    width: '220',
    prop: 'productOperatingCommissionRatio',
    isShow: true,
    checked: true,
    isInput: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '产品运营提成(￥)',
    width: '160',
    prop: 'productOperatingCommission',
    isShow: true,
    checked: true,
    isMoney: true,
  },
  {
    label: '采购开发',
    width: '150',
    prop: 'buyerName',
    isShow: true,
    checked: true,
  },
  {
    label: '采购开发地区',
    width: '150',
    prop: 'buyerArea',
    isShow: true,
    checked: true,
  },
  {
    label: '采购开发提成比例（%）',
    width: '200',
    prop: 'buyerCommissionRatio',
    isShow: true,
    checked: true,
    isInput: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '采购开发提成(￥)',
    width: '150',
    prop: 'buyerCommission',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '订单支持',
    width: '150',
    prop: 'orderSupport',
    isShow: true,
    checked: true,
  },
  {
    label: '订单支持地区',
    width: '150',
    prop: 'orderSupportArea',
    isShow: true,
    checked: true,
  },
  {
    label: '订单支持提成比例（%）',
    width: '220',
    prop: 'orderSupportCommissionRatio',
    isShow: true,
    checked: true,
    isInput: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '订单支持提成(￥)',
    width: '150',
    prop: 'orderSupportCommission',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },

  {
    label: '客户代表管理',
    width: '150',
    prop: 'businessManager',
    isShow: true,
    checked: true,
    isUser: true,
  },

  {
    label: '客户代表管理提成比例（%）',
    width: '240',
    prop: 'businessManagerCommissionRatio',
    isShow: true,
    checked: true,
    isInput: true,
    invisibleJudgment: true, //可见性判断
  },

  {
    label: '客户代表管理提成(￥)',
    width: '190',
    prop: 'businessManagerCommission',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },

  {
    label: '产品运营管理',
    width: '150',
    prop: 'productOperatingManager',
    isShow: true,
    checked: true,
    isUser: true,
  },

  {
    label: '产品运营管理提成比例（%）',
    width: '220',
    prop: 'productOperatingManagerCommissionRatio',
    isShow: true,
    checked: true,
    isInput: true,
    invisibleJudgment: true, //可见性判断
  },

  {
    label: '产品运营管理提成',
    width: '190',
    prop: 'productOperatingManagerCommission',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },

  {
    label: '采购员管理',
    width: '150',
    prop: 'buyerManager',
    isShow: true,
    checked: true,
    isUser: true,
  },

  {
    label: '采购员管理提成比例（%）',
    width: '220',
    prop: 'buyerManagerCommissionRatio',
    isShow: true,
    checked: true,
    isInput: true,
    invisibleJudgment: true, //可见性判断
  },

  {
    label: '采购员管理提成(￥)',
    width: '190',
    prop: 'buyerManagerCommission',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },

  {
    label: '订单支持管理',
    width: '150',
    prop: 'orderSupportManager',
    isShow: true,
    checked: true,
    isUser: true,
  },

  {
    label: '订单支持管理提成比例（%）',
    width: '220',
    prop: 'orderSupportManagerCommissionRatio',
    isShow: true,
    checked: true,
    isInput: true,
    invisibleJudgment: true, //可见性判断
  },

  {
    label: '订单支持管理提成(￥)',
    width: '160',
    prop: 'orderSupportManagerCommission',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },

  {
    label: '总提成金额(￥)',
    width: '150',
    prop: 'totalCommission',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
  {
    label: '公司利润',
    width: '150',
    prop: 'companyProfit',
    isShow: true,
    checked: true,
    isMoney: true,
    invisibleJudgment: true, //可见性判断
  },
]
export default columns

/**
 *
 * @param {*} fn
 * @returns
 */
export function columnsFilter(fn) {
  return columns.filter(fn)
}

export const statusList = [
  {
    code: '',
    name: '全部',
  },
  {
    code: '1',
    name: '待结算',
  },
  {
    code: '2',
    name: '处理中',
  },
  {
    code: '3',
    name: '审核中',
  },
  {
    code: '4',
    name: '已完成',
  },
]

export const settlementStatusList = [
  {
    code: '4',
    name: '审核中',
  },
  {
    code: '5',
    name: '已完成',
  },
  {
    code: '1',
    name: '录入中',
  },
  {
    code: '2',
    name: '审核驳回',
  },
  {
    code: '3',
    name: '已撤销',
  },
  {
    code: '6',
    name: '未结算',
  },
]

export const options = [
  {
    code: 0,
    name: '正常',
  },
  {
    code: 1,
    name: '异常',
  },
  {
    code: 2,
    name: '手动申请',
  },
]

export const fieldsDependNetProfit = [
  'businessCommission',
  'productOperatingCommission',
  'buyerCommission',
  'orderSupportCommission',
  'businessManagerCommission',
  'productOperatingManagerCommission',
  'buyerManagerCommission',
  'orderSupportManagerCommission',
]
