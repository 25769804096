/**
 * 订单结算列表
 */
import request from '@/core/services/request'

const responseData = {
  code: '000000',
  desc: '',
}
export function getListData(data = {}) {
  // return Promise.resolve({
  //   ...responseData,
  //   data: {
  //     data: mockTableData,
  //     total: 100,
  //   },
  // })

  return request({
    url: '/finance/settlementSalesOrder/listPage',
    method: 'post',
    data,
  })
}

/**
 * 获取按钮权限
 */
export function getButtonRoles() {
  return Promise.resolve({
    ...responseData,
    data: {
      settlementConditions: 1,
      commissionRule: 1,
      orderSettlement: 1,
    },
  })
}

/**
 * 设置结算专员
 */
export function setSettlemenSpecialist(settlementOrderList) {
  // return Promise.resolve({
  //   ...responseData,
  // })
  return request({
    url: '/finance/settlementSalesOrder/assignSettlementAttach',
    method: 'post',
    data: settlementOrderList,
  })
}

/**
 * 撤销
 */
export function revertSheet(settlementId) {
  // return Promise.resolve({
  //   ...responseData,
  // })

  return request({
    url: '/finance/settlementSalesOrder/revoke',
    method: 'post',
    params: {
      settlementId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 订单结算确认
 */
export function orderSettlementConfirm(data) {
  return request({
    url: '/finance/settlementSalesOrder/insertPO',
    method: 'post',
    data,
  })
}

export function orderSettlementUpdateConfirm(data) {
  return request({
    url: '/finance/settlementSalesOrder/updatePO',
    method: 'post',
    data,
  })
}

/**
 * 订单结算保存
 */
export function orderSettlementSave(data) {
  return request({
    url: '/finance/settlementSalesOrder/savePO',
    method: 'post',
    data,
  })
}

/**
 * 查询未结算订单信息
 *
 */

export function listWaitingSettlementInfo(settlementSalesOrderIds) {
  return request({
    url: '/finance/settlementSalesOrder/listWaitingSettlementInfo',
    method: 'post',
    data: settlementSalesOrderIds,
  })
}

export function checkOrderRelCostStatus(settlementSalesOrderIds) {
  return request({
    url: '/finance/settlementSalesOrder/checkOrderRelCostStatus',
    method: 'post',
    data: settlementSalesOrderIds,
  })
}

/**
 *
 * @returns 查询有结算单号的结算单信息
 */

export function getDetailForEdit(data) {
  return request({
    url: '/finance/settlementSalesOrder/getDetailForEdit',
    method: 'post',
    data,
  })
}

/**
 * 获取结算条件设置
 */
export function orderSettlementCondition() {
  return request({
    url: '/finance/settlementSetup/listPage',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 *修改结算条件
 */

export function updateConditionPO(data = {}) {
  return request({
    url: '/finance/settlementSetup/updatePO',
    method: 'post',
    data,
  })
}

/**
 * 订单结算详情
 */

export function getDetailBySettlementId(settlementId) {
  return request({
    url: '/finance/settlementSalesOrder/getDetailBySettlementId',
    method: 'post',
    params: {
      settlementId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 *人员提成汇总
 */

export function listCommissionSummary(settlementId) {
  return request({
    url: '/finance/settlementSalesOrder/listCommissionSummary',
    method: 'post',
    params: {
      settlementId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

export function listCommissionDetailVO(data = {}) {
  return request({
    url: '/finance/settlementSalesOrder/listCommissionDetailVO',
    method: 'post',
    data,
  })
}

/**
 * 人员提成汇总导出
 */

export function exportSummary(settlementId) {
  return request({
    url: '/finance/settlementSalesOrder/export',
    method: 'GET',
    responseType: 'blob',
    params: {
      settlementId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/**
 * 删除结算单
 */

export function deleteSalesOrder(settlementSalesOrderId) {
  return request({
    url: '/finance/settlementSalesOrder/deleteSalesOrder',
    method: 'POST',

    data: Array.isArray(settlementSalesOrderId)
      ? settlementSalesOrderId
      : [settlementSalesOrderId],
  })
}

export function getPurchesForFinance(data = {}) {
  return request({
    url: '/order/orderProduct/settlementParentProduct',
    method: 'POST',
    data,
    // headers: {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    // },
  })
}

export function getDetailBySettlementSalesOrderId(settlementSalesOrderId) {
  return request({
    url: '/finance/settlementSalesOrder/getDetailBySettlementSalesOrderId',
    method: 'POST',
    params: {
      settlementSalesOrderId,
    },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}
